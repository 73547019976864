export const columnsDataActiveTrades = [
  {
    Header: "USER",
    accessor: "user",
  },
  {
    Header: "RECENT ACTIVITY",
    accessor: "recentActivity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];
export const columnsDataUsersManagement = [
  {
    Header: "NAME",
    accessor: "username",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "DATE OF ACCOUNT CREATION",
    accessor: "created_at",
  },
  {
    Header: "AMOUNT SPENT",
    accessor: "amount",
  },
  {
    Header: "SUBSCRIPTION STATUS",
    accessor: "currentSubscription",
  },
];

export const columnsDataTradeHistory = [
  {
    Header: "DATE D’ENTREE",
    accessor: "Date d’entrée",
  },
  {
    Header: "POSITION",
    accessor: "Position",
  },
  {
    Header: "ORDER COIN",
    accessor: "Order coin",
  },
  {
    Header: "PRIX D’ENTREE",
    accessor: "Prix d’entré",
  },

  {
    Header: "PRIX DE SORTIE",
    accessor: "Prix de sortie",
  },
  {
    Header: "ROI",
    accessor: "Roi",
  },
  {
    Header: "LEVIER",
    accessor: "Levier",
  },
  {
    Header: "STATUS",
    accessor: "Status",
  },
  {
    Header: "DATE DE SORTIE",
    accessor: "Date de sortie",
  },
];
