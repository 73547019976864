import React, { useEffect, useState } from "react";

// ProRIse-admin imports
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Input,
  SimpleGrid,
  Spinner,
  Tag,
  TagLabel,
  Text,
} from "@chakra-ui/react";
import { setAuthToken } from "constants/api";
import apiInstance from "constants/api";
import { PRO_RISE } from "constants/apiConstants";
import toast from "react-hot-toast";
import Select from "react-select";

// Custom components

export default function ManagmentTrades() {
  const [plans, setPlans] = useState([]);
  const [traders, setTraders] = useState([]);
  const [selectedTraders, setSelectedTraders] = useState(
    Array.from({ length: 40 }, () => ({
      value: "",
      label: "",
    }))
  );


  const generateArray = async () => {
    try {
    
      setAuthToken(localStorage.getItem("jwt"));
      const { data } = await apiInstance.post(
        `${PRO_RISE.getLeaderBoardData}`,
        {
          searchCriteria: {
            pageSize: 0,
          },
        }
      );
      if (data?.success) {
        setTraders(data?.result);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    generateArray();
  }, []);

  // useEffect(() => {
  //   // Find the first trader in the array with isSelected set to true
  //   // const selectedOption = traders?.filter((trader) => trader.isSelected);
  //   // console.log(
  //   //   selectedOption?.map((item) => ({
  //   //     value: item?.encryptedUid,
  //   //     label: item?.nickName,
  //   //   }))
  //   // );

  //   // // If a selected option is found, set it as the default selected value
  //   // if (selectedOption) {
  //   //   setSelectedTraders(selectedOption);
  //   // }
  // }, [traders]);

  useEffect(() => {
    // Find all traders in the array with isSelected set to true
    const selectedOptions = traders
      ?.filter((trader) => trader.isSelected)
      ?.map((item) => ({
        value: item?.encryptedUid,
        label: item?.nickName,
      }));

    // Create a new array that holds our default selected traders
    const updatedSelectedTraders = [...selectedTraders];

    // Loop through our selectedOptions and update the updatedSelectedTraders array
    selectedOptions?.forEach((option, index) => {
      updatedSelectedTraders[index] = option;
    });

    setSelectedTraders(updatedSelectedTraders);
  }, [traders]);

  // console.log(selectedTraders);

  // const [array, setArray] = useState([]);
  // const onUpdateTrader = (e, index) => {
  //   const newArray = [...array];

  //   // Update the value at the specified index with the new value (e)
  //   newArray[index] = e;

  //   // Update the state with the new array
  //   setArray(newArray);
  // };

  const updateTrade = async () => {
    const uniqueValuesArray = [
      ...new Set(selectedTraders?.map((item) => item?.value)),
    ];

    try {
      setAuthToken(localStorage.getItem("jwt"));
      const { data } = await apiInstance.post(
        `${PRO_RISE.selectTradersToDisplay}`,
        {
          traders: uniqueValuesArray,
        }
      );
      if (data?.success) {
        toast.success(` Traders is updated successFully`);
        // setArray([]);
      } else {
        toast.error(data.msg);
      }
    } catch (error) {
      toast.error(error.msg);
    }
  };

  if (!traders?.length) {
    return (
      <Center h={"70vh"}>
        <Spinner size="xl" thickness="4px" speed="0.65s" />
      </Center>
    );
  }

  const options = traders?.map((element) => ({
    value: element.encryptedUid,
    label: element.nickName,
  }));

  const handleChange = (value, index) => {
    const newSelectedTraders = [...selectedTraders];
    newSelectedTraders[index] = value;
    setSelectedTraders(newSelectedTraders);
  };

  return (
    <Box>
      <Flex direction={"column"} gap={10}>
        <Text
          fontSize={{ xl: "26px", lg: "26px", md: "26px", sm: "16px" }}
          fontWeight="bold"
          display={"flex"}
          gap={3}
        >
          Choose The Traders Who Will Be Copied
        </Text>

     
          {selectedTraders?.map((_, indexItem) => (
            <Flex direction="row" gap={5} key={indexItem}>
              <Tag w={270} bg="#85A3DE" color="white" borderRadius="full">
                <TagLabel
                  fontSize={{
                    xl: "20px",
                    lg: "20px",
                    md: "20px",
                    sm: "16px",
                  }}
                  px={2}
                  letterSpacing={3}
                >
                  {"Select trader" + " : " + (indexItem + 1)}
                </TagLabel>
              </Tag>

              <Select
                value={selectedTraders[indexItem]}
                onChange={(value) => handleChange(value, indexItem)}
                isClearable
                isSearchable
                placeholder=""
                options={options}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: "#D9D9D9",
                    borderColor: "#D9D9D9",
                    color: "black",
                    width: 300,
                    height: 8,
                    fontWeight: 600,
                  }),
                  menu: (provided) => ({
                    ...provided,
                    backgroundColor: "white",
                    color: "black", // Change this to the desired background color
                    fontWeight: 600,
                  }),
                }}
              />
              {/* Add your Tag component here */}
            </Flex>
          ))}
     
      </Flex>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          bg={"green.400"}
          float={"right"}
          color={"black"}
          width={"35"}
          _hover={{ color: "black", bg: "green.400" }}
          borderRadius={0}
          onClick={() => updateTrade()}
        >
          Apply
        </Button>
      </div>
    </Box>
  );
}
