import React, { useEffect, useState } from "react";

// ProRIse-admin imports
import { Box, Center, Divider, Flex, Spinner, Text } from "@chakra-ui/react";

// Custom components
import { dashboardData } from "constants/constants";
import BasicCard from "components/card/BasicCard";
import apiInstance from "constants/api";
import { PRO_RISE } from "constants/apiConstants";
import { setAuthToken } from "constants/api";
import { actions } from "react-table";
import moment from "moment/moment";

export default function Dashboard() {
  const [dashboardData1, setDashboardData1] = useState({
    users: "",
    subcriptions: "",
    traders: "",
    revenue: "",
    activities: [],
  });

  const fetchData = async () => {
    try {
      var apidata = (
        await apiInstance.post(`${PRO_RISE.getTotalSubscriptions}`)
      )?.data;
      const subcriptions = apidata.success ? apidata.sum : 0;
      apidata = (await apiInstance.post(`${PRO_RISE.getTotalUsers}`)).data;
      var totalUser = apidata.success ? apidata.data : 0;
      apidata = (await apiInstance.post(`${PRO_RISE.netRevenue}`))?.data;
      const revenue = apidata.success ? apidata.netRevenue : 0; //console.log(revenue);
      apidata = (await apiInstance.post(`${PRO_RISE.getScriptionActivity}`))
        ?.data;
      const activities = apidata?.success ? apidata?.recentActivity : "";
      var activitieslist = new Array();
      activities?.map((item) => {
        activitieslist.push({
          user: item.username,
          recentActivity:
            item.username + " bought new package " + item.subscriptionname,
          date:
            moment(item?.created_at)?.format("Do MMMM YY") ??
            "03/10/2023 06:31:22",
        });
      });
      apidata = (await apiInstance.post(`${PRO_RISE.getActiveUsers}`))?.data;
      const activeUsers = apidata.success ? apidata.totalActiveUsers : 0;

      setDashboardData1({
        users: totalUser,
        subcriptions: subcriptions,
        traders: activeUsers,
        revenue: revenue,
        activities: activitieslist,
      });
    } catch (error) {}
  };

  useEffect(() => {
    // console.log(localStorage.getItem("jwt"));
    setAuthToken(localStorage.getItem("jwt"));
    fetchData();
  }, []);

  const renderData = (index) => {
    if (!dashboardData1.subcriptions) {
      return (
        <Center h={50}>
          <Spinner size="lg" thickness="4px" speed="0.65s" />
        </Center>
      );
    }
    if (index === 0) {
      return dashboardData1.users;
    } else if (index === 1) {
      return "$" + dashboardData1.subcriptions;
    } else if (index === 2) {
      return dashboardData1.traders;
    } else {
      return "$" + dashboardData1.revenue;
    }
  };


  return (
    <Box>
      <Flex direction={"row"} justifyContent={"space-between"} px={37}>
        {dashboardData?.map((item, index) => (
          <Flex
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Text
              mt="16px"
              mb="16px"
              color={"gary.200"}
              fontSize="32px"
              fontWeight="400"
            >
              {item.title}
            </Text>
            <Text
              mt="16px"
              mb="16px"
              color={index === 1 || index === 3 ? "green.400" : "gary.200"}
              fontSize="32px"
              fontWeight="700"
            >
              {renderData(index)}
            </Text>
          </Flex>
        ))}
      </Flex>
      <Divider borderColor={"white"} variant={"solid"} borderWidth={1} />
      <BasicCard
        table={true}
        data={dashboardData1?.activities ?? []}
        heading={"Recent Activities"}
      />
    </Box>
  );
}
