import React from "react";

// ProRIse-admin imports
import { Flex, Image, useColorModeValue } from "@chakra-ui/react";

// Custom components
import DashboardLogo from "assets/img/ProRise.png";
import Admin from "assets/img/ADMINS.png";

export function SidebarBrand() {
  //   color mode
  let logoColor = useColorModeValue("navy.700", "white");

  return (
    <Flex align="center" direction="column" g>
      <Image
        src={DashboardLogo}
        w="100%"
        // ml="-30px"
        // my="14px"
        color={logoColor}
      />
      <Image
        src={Admin}
        w="30%"
        // ml="-30px"
        // my="14px"
        color={logoColor}
      />
    </Flex>
  );
}

export default SidebarBrand;
