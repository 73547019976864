import { IoShareSharp } from "react-icons/io5";
import { MdStar } from "react-icons/md";
import { BiCopy } from "react-icons/bi";

// export const BASE_URL = 'http://18.194.220.237:3000/api/'


export const buttonArray = [
  {
    title: "Partager",
    icon: IoShareSharp,
  },
  {
    title: "Ajouter aux favoris",
    icon: MdStar,
  },
  {
    title: "Copier",
    icon: BiCopy,
  },
];

export const tabsArray = [
  {
    id: 0,
    title: "Trades actifs",
  },
  {
    id: 1,
    title: "Historique des trades",
  },
  {
    id: 2,
    title: "Statistiques",
  },
];

export const dashboardData = [
  {
    title: "Total Users",
  },
  {
    title: "Active Subscriptions",
  },
  {
    title: "Active Traders",
  },
  {
    title: "Revenus net ",
  },
];
