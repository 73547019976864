// import React from "react";

// Admin Imports
import MainDashboard from "views/admin/dashboard";
import Analytics from "views/admin/analytics";
import UserManagement from "views/admin/userManagement";

// // Onboarding Imports
// import OnboardingCentered from "views/auth/onboarding";
import profile from "./assets/img/dashboards/svgIcon/Frame 427319644.png";
import dashboard from "./assets/img/dashboards/svgIcon/grid.svg";
import topTrand from "./assets/img/dashboards/svgIcon/users.svg";
import copy from "./assets/img/dashboards/svgIcon/copy.svg";
import Analytiques from "./assets/img/dashboards/svgIcon/circle-chart-1.svg";
import shear from "./assets/img/dashboards/svgIcon/share.svg";
// import Settings from "views/admin/settings";
// import TraderDetails from "views/admin/TopTraders/traderDetail";

import Login from "views/auth/login";
import ApiManagment from "views/admin/apiManagment";
import RiskManagment from "views/admin/riskManagment";
import ManagmentTrades from "views/admin/managmentTrades";
import TradeFinder from "views/admin/managmentTrades/tradeFinder";

const routes = [
  {
    name: "Dashboard Overview",
    layout: "/admin",
    path: "/dashboard",
    icon: <img src={dashboard} />,
    component: MainDashboard,
  },
  {
    name: "Users Management",
    layout: "/admin",
    path: "/users-management",
    icon: <img src={topTrand} />,
    component: UserManagement,
    secondary: true,
  },
  {
    name: "Managment Traders From Binance",
    layout: "/admin",
    icon: <img src={copy} />,
    path: "/managment-traders",
    component: ManagmentTrades,
  },
  {
    name: "Risk Management",
    layout: "/admin",
    path: "/risk-management",
    icon: <img src={Analytiques} />,
    component: RiskManagment,
  },
  {
    name: "Performance Analytics",
    layout: "/admin",
    path: "/performance-analytics",
    icon: <img src={shear} />,
    component: Analytics,
  },
  // {
  //   name: "API managment",
  //   layout: "/admin",
  //   path: "/api-managment",
  //   // icon: <img src={profile} />,
  //   component: ApiManagment,
  // },
  {
    layout: "/auth",
    path: "/onboarding",
    // component: OnboardingCentered,
  },
  {
    layout: "/auth",
    path: "/login",
    component: Login,
  },
];

export default routes;
