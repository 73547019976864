import React from "react";
// ProRIse-admin imports
import { Box, Flex, HStack, Icon, Stack, Text } from "@chakra-ui/react";
//   Custom components
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";
import { BiLogOutCircle } from "react-icons/bi";
import { useDispatch } from "react-redux";
import persistStore from "redux-persist/es/persistStore";
import { store } from "store/store";
import { logout } from "store/actions";

// FUNCTIONS

function SidebarContent(props) {
  const { routes } = props;
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    const persistor = persistStore(store);
    persistor.purge();
  };
  // SIDEBAR
  return (
    <Flex direction="column" height="100%" pt="25px">
      <Brand />
      <Stack direction="column" mb="auto" mt={8}>
        <Box ps="20px" pe={{ md: "16px", "2xl": "1px" }}>
          <Links routes={routes} />
          <Box cursor={"pointer"} onClick={handleLogout}>
            <HStack py="15px" ps="10px" borderRadius={"12px"}>
              <Flex w="100%" alignItems="center" justifyContent="center">
                <Box me="18px">
                  <Icon
                    as={BiLogOutCircle}
                    sx={{ fontSize: 23, cursor: "pointer" }}
                  />
                </Box>
                <Text
                  me="auto"
                  textTransform={"capitalize"}
                  color={"#A0AEC0"}
                  fontSize={20}
                >
                  {"Logout"}
                </Text>
              </Flex>
            </HStack>
          </Box>
        </Box>
      </Stack>
    </Flex>
  );
}

export default SidebarContent;
