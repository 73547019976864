// ProRIse-admin imports
import { Box, Grid, GridItem } from "@chakra-ui/react";

// Custom components
import React, { useEffect, useState } from "react";
import TotalSpent from "../dashboard/components/TotalSpent";
import { PRO_RISE } from "constants/apiConstants";
import apiInstance from "constants/api";
import { setAuthToken } from "constants/api";

export default function Analytics() {
  const [analyticsData, setAnalyticsData] = useState({});
  const fetchData = async () => {
    setAuthToken(localStorage.getItem("jwt"));
    const { data } = await apiInstance.post(`${PRO_RISE.getAnalytics}`);

    setAnalyticsData(data?.data);
  };

  useEffect(() => {
    fetchData();
  }, []);
  console.log(analyticsData);

  return (
    <Box>
      <Grid
        templateRows="repeat(1, 1fr)"
        templateColumns="repeat(3, 1fr)"
        gap={8}
      >
        <GridItem colSpan={4}>
          <TotalSpent
            heading="Performances"
            design={2}
            analyticsData={analyticsData ?? {}}
          />
        </GridItem>
      </Grid>
    </Box>
  );
}
