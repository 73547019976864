import React, { useMemo, useState } from "react";
import { setAuthToken } from "constants/api";
/* eslint-disable */
import {
  Box,
  Button,
  Center,
  Flex,
  Icon,
  Image,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";

import profile from "assets/img/dashboards/Profile.png";

// Custom components
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

import UserCard from "components/card/UserCard";
import apiInstance from "constants/api";
import { PRO_RISE } from "constants/apiConstants";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";

export default function GlobalTable(props) {
  const { columnsData, tableData, slice, p, userManagement } = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = tableInstance;
  initialState.pageSize = userManagement ? 20 : 10;
  initialState.pageIndex = 0;

  let iconColor = useColorModeValue("secondaryGray.900", "white");
  let activeTextColor = useColorModeValue("secondaryGray.900", "white");
  let activebgColor = useColorModeValue("secondaryGray.900", "#0c0d22");
  let activeIconColor = useColorModeValue("secondaryGray.900", "#0c0d22");

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [userProps, setUserProps] = useState({});

  async function dataGrabber(data) {
    onOpen();
    setUserProps(data);
  }

  const textColor = useColorModeValue("secondaryGray.900", "white");

  return (
    <Box p={p ? p : 0}>
      <TableContainer>
        {data?.length ? (
          <Table
            {...getTableProps()}
            variant="simple"
            color="gray.500"
            style={{
              borderCollapse: userManagement ? "separate" : "collapse",
              borderSpacing: "0 1em",
            }}
          >
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr
                  {...headerGroup.getHeaderGroupProps()}
                  key={index}
                  bg={userManagement ? "transparent" : `purpul.200`}
                  textAlign={"center"}
                >
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="10px"
                      color={"white"}
                      key={index}
                      fontFamily="Urbanist"
                      fontSize={"14px"}
                      w={"40%"}
                      borderBottomWidth={userManagement && "0px"}
                    >
                      {column.render("Header")}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>

            <Tbody
              {...getTableBodyProps()}
              border={userManagement ? "1px" : `0`}
              borderColor={userManagement ? "white" : `transparent`}
            >
              {page?.map((row, index) => {
                prepareRow(row);
                return (
                  <Tr
                    {...row.getRowProps()}
                    key={index}
                    cursor={userManagement && "pointer"}
                    onClick={() => {
                      userManagement && dataGrabber(row.original);
                    }}
                  >
                    {row.cells.map((cell, index) => {
                      let data = "";
                      if (cell.column.Header === "DATE") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="400"
                          >
                            {cell.value}
                          </Text>
                        );
                      } else if (cell.column.Header === "USER") {
                        data = (
                          <Flex direction={"row"} alignItems={"center"} gap={4}>
                            <Image src={profile} w={5} h={5} />
                            <Text
                              color={textColor}
                              fontSize="sm"
                              fontWeight="400"
                            >
                              {cell.value}
                            </Text>
                          </Flex>
                        );
                      } else if (cell.column.Header === "RECENT ACTIVITY") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="400"
                          >
                            {cell.value}
                          </Text>
                        );
                      } else if (cell.column.Header === "NAME") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="400"
                          >
                            {cell.value}
                          </Text>
                        );
                      } else if (cell.column.Header === "EMAIL") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="400"
                          >
                            {cell.value}
                          </Text>
                        );
                      } else if (
                        cell.column.Header === "DATE OF ACCOUNT CREATION"
                      ) {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="400"
                          >
                            {cell.value}
                          </Text>
                        );
                      } else if (cell.column.Header === "AMOUNT SPENT") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="400"
                          >
                            {cell.value}
                          </Text>
                        );
                      } else if (cell.column.Header === "SUBSCRIPTION STATUS") {
                        data = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="400"
                          >
                            {cell.value}
                          </Text>
                        );
                      }
                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          fontSize={{ sm: "14px", xl: "16px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          border={userManagement ? "1px" : `0`}
                          borderColor={userManagement ? "white" : `transparent`}
                        >
                          {data}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        ) : (
          <Center h={400}>
            <Spinner size="xl" thickness="4px" speed="0.65s" />
          </Center>
        )}
      </TableContainer>
      <UserCard
        isOpen={isOpen}
        onClose={onClose}
        userProps={userProps}
        setUserProps={setUserProps}
      />
      {data?.length > pageSize ? (
        <Box py={5}>
          <Flex alignItems={"center"} justifyContent={"center"} gap={2}>
            <Button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              borderRadius={10}
              px={0}
              _hover={{ bg: activeIconColor }}
            >
              <Icon as={MdOutlineArrowBackIos} color={iconColor} />
            </Button>
            {pageOptions?.map((item, index) => (
              <Button
                onClick={() => gotoPage(index)}
                px={0}
                borderRadius={10}
                color={index === pageIndex ? activeTextColor : iconColor}
                _hover={{
                  bg:
                    index === pageIndex
                      ? !activeTextColor
                      : "rgba(87, 148, 250, 0.08)",
                }}
                bg={
                  index === pageIndex
                    ? activebgColor
                    : "rgba(87, 148, 250, 0.08)"
                }
              >
                {++item}
              </Button>
            ))}
            <Button
              onClick={() => nextPage()}
              disabled={!canNextPage}
              px={0}
              borderRadius={10}
              _hover={{ bg: activeIconColor }}
            >
              <Icon
                as={MdOutlineArrowForwardIos}
                // fontSize={20}
                color={iconColor}
              />
            </Button>
          </Flex>
        </Box>
      ) : null}
    </Box>
  );
}
