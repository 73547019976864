import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import apiInstance from "constants/api";
import { PRO_RISE } from "constants/apiConstants";
import { toast } from "react-hot-toast";

// export const userLogin = createAsyncThunk(
//   "user/Login",
//   async (params, { rejectWithValue }) => {
//     try {
//       const { data } = await apiInstance.post(`${PRO_RISE.login}`, params);
//       toast.success("Successfully Login");
//       return data;
//     } catch (error) {
//       toast.error(error.message);
//       return rejectWithValue(error.message);
//     }
//   }
// );

export const userLogin = createAction("user/Login");

export const logout = createAction("user/logout");
