import React, { useEffect, useState } from "react";

import { saveAs } from "file-saver";
import Papa from "papaparse";

// ProRIse-admin imports
import {
  Box,
  Divider,
  Flex,
  Text,
  Input,
  Button,
  Icon,
} from "@chakra-ui/react";

// Custom components
import Card from "components/card/Card";
import UserCard from "components/card/UserCard";
import GlobalTable from "components/Table/table";

import { columnsDataUsersManagement } from "variables/columnsData";
import { columnsDataActiveTrades } from "variables/columnsData";
import tableUserManagement from "variables/tableUserManagement.json";
import tableDataDevelopment from "variables/tableDataDevelopment.json";
import apiInstance from "constants/api";
import { PRO_RISE } from "constants/apiConstants";
import { setAuthToken } from "constants/api";
import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import { TbMessageCircleSearch } from "react-icons/tb";

export default function UserManagement() {
  const [users, setusers] = useState([]);
  const [itemOffset, setItemOffset] = useState(1);
  const [search, setSearch] = useState("");
  const fetchData = async (pageNo, username) => {
    var apidata = (
      await apiInstance.post(`${PRO_RISE.getAllUsers}`, {
        searchCriteria: { currentPage: pageNo, username: username ?? "" },
      })
    ).data;
    const user = apidata.success ? apidata.users : "";
    var userlist = new Array();
    user?.forEach((element) => {
      userlist.push({
        ...element,
        id: element.id,
        email: element.email,
        username: element.username,
        currentSubscription: element.currentSubscription ?? "null",
        amount: element.amount ?? 0,
      });
    });

    setusers(userlist);
  };

  useEffect(() => {
    setAuthToken(localStorage.getItem("jwt"));
    fetchData(itemOffset);
  }, [itemOffset]);

  const onSearch = () => {
    fetchData(itemOffset, search);
  };

  const exportToCSV = () => {
    const csv = Papa.unparse(users);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
    saveAs(blob, `users.csv`);
  };

  return (
    <Box>
      <Flex direction={"row"} justifyContent={"space-between"} px={1}>
        <Flex justifyContent={"space-between"} width={500} p={2} gap={3}>
          <Input
            bg={"white"}
            iconPlacement="right"
            placeholder="Rechercher un trader"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Button colorScheme="blue" rounded={"lg"} onClick={onSearch}>
            Search
          </Button>
        </Flex>
        <Flex justifyContent={"space-between"} p={2}>
          <Button colorScheme="green" rounded={"lg"} onClick={exportToCSV}>
            Export
          </Button>
        </Flex>
      </Flex>
      <Divider borderColor={"white"} variant={"solid"} borderWidth={1} />

      <Flex direction={"row"} justifyContent={"right"} gap={2} py={2}>
        <Button
          loadingText="Loading"
          spinnerPlacement="start"
          variant="brand"
          fontWeight="500"
          mb={{ base: "30px", sm: "0px" }}
          bg="rgba(255, 255, 255, 0.08)"
          borderRadius="6px"
          _hover={{ bg: "rgba(255, 255, 255, 0.08)" }}
          onClick={() => setItemOffset(itemOffset - 1)}
          gap={3}
          isDisabled={itemOffset === 1 && true}
        >
          <Icon as={ArrowLeftIcon} />
        </Button>
        <Button
          loadingText="Loading"
          spinnerPlacement="start"
          variant="brand"
          fontWeight="500"
          mb={{ base: "30px", sm: "0px" }}
          bg="rgba(255, 255, 255, 0.08)"
          borderRadius="6px"
          _hover={{ bg: "rgba(255, 255, 255, 0.08)" }}
          onClick={() => setItemOffset(itemOffset + 1)}
          gap={3}
          isDisabled={itemOffset === users?.length && true}
        >
          <Icon as={ArrowRightIcon} />
        </Button>
      </Flex>

      <Card
      // onClick={handleClick}
      >
        <GlobalTable
          columnsData={columnsDataUsersManagement}
          tableData={users ?? []}
          tableHeading="Trades actifs"
          userManagement={true}
          slice={false}
          p={0}
          bg={"none"}
        />
      </Card>
    </Box>
  );
}
