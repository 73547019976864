export const PRO_RISE = {
  login: "auth/login",
  addAdminUser: "user/addAdminUser",
  changePassword: "user/changePassword",
  updateUser: "user/update",
  updatePlan: "plan/createPlan",
  defaultCapitalPercent: "exchange/defaultCapitalPercent",
  setDefaultCapitalPercent: "exchange/setDefaultCapitalPercent",
  getPlans: "plan/getPlans",
  getLeaderBoardData: "leaderboards/getLeaderboardsData",
  getScriptionActivity: "user/getSubscriptionActivity",
  getTotalSubscriptions: "user/getTotalSubscriptions",
  getTotalUsers: "user/getTotalUsers",
  netRevenue: "user/netRevenue",
  getActiveUsers: "user/getActiveUsers",
  getAllUsers: "user/getAllUsers",
  getPlanSpecifications: "plan/getPlanSpecifications",
  specifyPlanCapital: "plan/specifyPlanCapital",
  tradersInPlan: "plan/tradersInPlan",
  getAnalytics: "user/getAnalytics",
  selectTradersToDisplay: "leaderboards/selectTradersToDisplay",
};
