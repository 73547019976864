// ProRIse-admin imports
import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import LineChart from "components/charts/LineChart";
import LineAreaChart from "components/charts/LineAreaChart";
import React from "react";

import wallet from "../../../../assets/img/dashboards/svgIcon/wallet.svg";
import present from "../../../../assets/img/dashboards/svgIcon/presentation-chart.svg";
import { TradersCardData } from "constants/constants";
import moment from "moment";
import ReactApexChart from "react-apexcharts";

export default function TotalSpent(props) {
  const { analyticsData, ...rest } = props;

  const { revenueEachDay = {} } = analyticsData;

  // ProRIse-admin color mode

  const textColor = useColorModeValue("white", "white");
  const textColorSecondary = useColorModeValue("gray.200", "gray.200");
  const bgButton = useColorModeValue(
    "rgba(0, 0, 0, 0.4)",
    "rgba(0, 0, 0, 0.4)"
  );
  const bgHover = useColorModeValue(
    { bg: "rgba(0, 0, 0, 0.4)" },
    { bg: "rgba(0, 0, 0, 0.4)" }
  );

  const groupFinal = Object?.entries(revenueEachDay ?? {})?.map(
    ([date, totalY]) => ({
      x: new Date(date),
      y: totalY?.toFixed(0),
    })
  );

  const lineChartOptionsTotalSpent = {
    chart: {
      foreColor: "#A3AED0",
      toolbar: {
        show: false,
      },
    },
    colors: ["#29C5EE", "#29C5EE"],
    markers: {
      size: 0,
      colors: "white",
      strokeColors: "#29C5EE",
      strokeWidth: 3,
      strokeOpacity: 20,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      showNullDataPoints: true,
    },
    tooltip: {
      theme: "dark",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "string",
      labels: {
        formatter: function (value) {
          return moment(value).format("MMM DD");
        },
        style: {
          colors: "#A3AED0",
          fontSize: "16px",
          fontWeight: "500",
          fontFamily: "Urbanist",
        },
      },
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
    },
    yaxis: {
      show: true,
      lines: {
        show: true,
      },
      labels: {
        formatter: function (value) {
          return `${parseFloat(value).toFixed(0)}`;
        },
        style: {
          colors: "#A3AED0",
          fontSize: "16px",
          fontWeight: "500",
          fontFamily: "Urbanist",
        },
      },
    },
    legend: {
      show: true,
    },
  };

  return (
    <Card
      justifyContent="center"
      align="center"
      direction="column"
      w="100%"
      mb="0px"
      {...rest}
      bg={"rgba(0, 0, 0, 0.4)"}
    >
      <Box ps="0px" pe="20px" pt="5px">
        <Flex
          direction={"row"}
          align="center"
          justifyContent={"space-between"}
          w="100%"
        >
          <Text
            color={textColor}
            fontSize={{ xl: "34px", lg: "34px", md: "34px", sm: "16px" }}
            textAlign="start"
            fontWeight="700"
            lineHeight="100%"
          >
            {props?.heading}
          </Text>
        </Flex>
        {props?.design === 2 && (
          <SimpleGrid
            columns={{ base: 2, md: 5, lg: 5, xl: 5, sm: 2 }}
            gap="20px"
            marginTop={props?.heading ? 10 : 0}
          >
            {[1, 2, 3, 4, 5].map((item, index) => {
              return (
                <>
                  <Flex direction={"column"} w="100%" align={"start"} pt={3}>
                    <Center>
                      <Flex direction={"row"} gap={1}>
                        <img src={present} />
                        <Text
                          color={"gray.200"}
                          fontSize="14px"
                          lineHeight="100%"
                          fontWeight="600"
                        >
                          {index === 0
                            ? "Earnings today"
                            : index === 1
                            ? "Earnings last 7 days"
                            : index === 2
                            ? "% of accounts activated (paid)"
                            : index === 3
                            ? "Total customers"
                            : index === 4
                            ? "Total accounts created"
                            : item?.text1}
                        </Text>
                      </Flex>
                    </Center>
                    <Text
                      color={index === 0 || index === 1 ? "green.300" : "white"}
                      fontSize="26px"
                      lineHeight="100%"
                      fontWeight="600"
                      pt={"2"}
                    >
                      {item?.textvalue1}
                      {index === 0
                        ? analyticsData?.statistics?.lastSevenDaysRev ??
                          0 + " € "
                        : index === 1
                        ? analyticsData?.statistics?.percentageSubscribed ??
                          0 + " € "
                        : index === 2
                        ? analyticsData?.statistics?.todayRev ?? 0 + " % "
                        : index === 3
                        ? analyticsData?.statistics?.totalSubscribedUsers ?? 0
                        : index === 4
                        ? analyticsData?.statistics?.totalUsers ?? 0
                        : null}
                    </Text>
                  </Flex>
                </>
              );
            })}
          </SimpleGrid>
        )}
      </Box>
      <Flex w="100%" flexDirection={{ base: "column", lg: "row" }}>
        <Box minH="276px" minW="100%" mt="20px">
          <Flex direction={"row"} w="100%" gap={1} justifyContent={"start"}>
            <img src={present} />
            <Text
              color={textColorSecondary}
              fontSize="14px"
              textAlign="end"
              lineHeight="100%"
              fontWeight="600"
            >
              {"Valeur 30 jours"}
            </Text>
          </Flex>
          <ReactApexChart
            options={lineChartOptionsTotalSpent}
            series={[
              {
                name: "Earnings € ",
                data: groupFinal?.reverse(),
              },
            ]}
            type="area"
            width="100%"
            height="100%"
          />
          {/* <LineAreaChart
            chartData={}
            chartOptions={lineChartOptionsTotalSpent}
            height={"365px"}
          /> */}
        </Box>
      </Flex>
    </Card>
  );
}
