import React, { useEffect, useState } from "react";

// ProRIse-admin imports
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Input,
  Spinner,
  Tag,
  TagLabel,
  Text,
} from "@chakra-ui/react";
import apiInstance from "constants/api";
import { PRO_RISE } from "constants/apiConstants";
import { toast } from "react-hot-toast";
import { setAuthToken } from "constants/api";

// Custom components

export default function RiskManagment() {
  const [specification, setSpecification] = useState({});

  const [plans, setPlans] = useState([]);

  const fetchData = async (plan) => {
    setAuthToken(localStorage.getItem("jwt"));
    const { data } = await apiInstance.post(
      `${PRO_RISE.getPlanSpecifications}`,
      {
        plan: plan,
      }
    );

    return data?.planSpecifications;
  };

  const generateArray = async () => {
    try {
      const sliverData = await fetchData("SILVER");
      const goldData = await fetchData("GOLD");
      const plitinumData = await fetchData("PLATINUM");
      setPlans([sliverData, goldData, plitinumData]);
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    generateArray();
  }, []);

  // Function to handle the API call
  const updateDefaultCapitalPercent = async (planName) => {
    try {
      setAuthToken(localStorage.getItem("jwt"));
      const { data } = await apiInstance.post(
        `${PRO_RISE.specifyPlanCapital}`,
        {
          plan: planName,
          capitalPercent: specification?.capitalPercent,
          maxTrades: parseInt(specification?.maxTrades),
        }
      );
      if (data.success) {
        toast.success(`${planName} Plan is updated successFully`);
      } else {
        toast.success(`Some feilds are Null`);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Box>
      <Flex direction={"column"} gap={10}>
        <Text
          fontSize={{ xl: "26px", lg: "26px", md: "26px", sm: "16px" }}
          fontWeight="bold"
          display={"flex"}
          gap={3}
        >
          Choose the risk per trade
        </Text>
        {plans.length ? (
          plans?.map((item, index) => (
            <>
              <Tag
                w={300}
                display={"flex"}
                justifyContent={"center"}
                bg={"#85DE9E"}
                color={"white"}
                borderRadius="full"
                h={10}
              >
                <TagLabel
                  fontSize={{ xl: "20px", lg: "20px", md: "20px", sm: "16px" }}
                  letterSpacing={3}
                  color={"#0075FF"}
                >
                  {`Pack ${item?.planName}`}
                </TagLabel>
              </Tag>
              <Flex direction={"row"} gap={5}>
                <Tag w={500} bg={"#85A3DE"} color={"white"} borderRadius="full">
                  <TagLabel
                    fontSize={{
                      xl: "20px",
                      lg: "20px",
                      md: "20px",
                      sm: "16px",
                    }}
                    letterSpacing={3}
                    px={2}
                  >
                    Allocate % per trade based on capital :
                  </TagLabel>
                </Tag>
                {/* <Input placeholder="key" w={"5%"} bg={"white"} />{" "} */}
                <Input
                  placeholder="key"
                  w={"5%"}
                  bg={"white"}
                  defaultValue={item?.capitalPercent}
                  onChange={(e) =>
                    setSpecification({
                      ...specification,
                      capitalPercent: e.target.value,
                    })
                  }
                />
                <Text
                  fontSize={{ xl: "26px", lg: "26px", md: "26px", sm: "16px" }}
                  fontWeight="bold"
                >
                  %
                </Text>
              </Flex>
              <Flex direction={"row"} gap={5}>
                <Tag w={500} bg={"#85A3DE"} color={"white"} borderRadius="full">
                  <TagLabel
                    fontSize={{
                      xl: "20px",
                      lg: "20px",
                      md: "20px",
                      sm: "16px",
                    }}
                    letterSpacing={3}
                    px={2}
                  >
                    Maximum number of traders :
                  </TagLabel>
                </Tag>
                <Input
                  placeholder="trades"
                  w={"5%"}
                  bg={"white"}
                  defaultValue={item?.maxTrades}
                  onChange={(e) =>
                    setSpecification({
                      ...specification,
                      maxTrades: e.target.value,
                    })
                  }
                />
              </Flex>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  bg={"green.400"}
                  float={"right"}
                  color={"black"}
                  width={"35"}
                  _hover={{ color: "black", bg: "green.400" }}
                  borderRadius={0}
                  onClick={() => updateDefaultCapitalPercent(item?.planName)}
                >
                  Apply
                </Button>
              </div>
              <Divider
                borderColor={"white"}
                variant={"solid"}
                borderWidth={1}
              />
            </>
          ))
        ) : (
          <Center h={"50vh"}>
            <Spinner size="lg" thickness="4px" speed="0.65s" />
          </Center>
        )}
      </Flex>
         
    </Box>
  );
}
