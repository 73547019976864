import { createSlice } from "@reduxjs/toolkit";
import { logout } from "store/actions";
import { userLogin } from "store/actions";

const initialState = {
  login: {},
  isLoading: false,
  isSuccess: false,
  errorMessage: "",
  auth: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: {
    [userLogin]: (state, { payload }) => {
      state.login = payload;
      localStorage.setItem("jwt", payload.token);
      state.auth = true;
    },
    [logout]: (state, { payload }) => {
      state.login = payload;
      localStorage.clear();
      state.auth = false;
    },
  },
});

export default userSlice.reducer;
