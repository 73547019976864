import Card from "./Card";
import sample from "../../assets/img/dashboards/Profile.png";
import {
  Flex,
  Box,
  Button,
  Image,
  Text,
  Input,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  Avatar,
} from "@chakra-ui/react";
import { useRef } from "react";
import apiInstance from "constants/api";
import { toast } from "react-hot-toast";
import { PRO_RISE } from "constants/apiConstants";

export default function UserCard(props) {
  const { isOpen, onClose, onSubmit, userProps, setUserProps } = props;
  const cancelRef = useRef();

  const updateData = async () => {
    delete userProps?.amount;
    try {
      const { data } = await apiInstance.post(`${PRO_RISE.updateUser}`, {
        firstName: userProps.firstName ?? "",
        lastName: userProps.lastName ?? "",
        rol: userProps.role,
      });
      if (data.success) {
        onClose();
        toast.success(data?.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent backgroundColor={"white"} color={"black"}>
        <AlertDialogHeader>Apply Changes?</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <Flex
            direction={"column"}
            rounded={"lg"}
            p={4}
            // alignItems={"center"}
            w={"full"}
          >
            <Flex
              direction={"row"}
              pb={8}
              color={"black"}
              alignItems={"center"}
              gap={3}
            >
              <Avatar src={userProps && userProps?.profilePicURL} />
              <Text fontSize={"18px"} fontWeight={"bold"} pt={2}>
                {userProps.username}
              </Text>
            </Flex>
            <Flex direction={"column"} w={"full"}>
              <Flex direction={"column"} color={"black"} pb={2}>
                <Text>First Name</Text>
                <Input
                  placeholder={userProps.firstName}
                  value={userProps.firstName}
                  variant="outline"
                  _placeholder={{ color: "gray.500" }}
                  backgroundColor={"#8080801a"}
                  onChange={(e) =>
                    setUserProps({
                      ...userProps,
                      firstName: e.target.value,
                    })
                  }
                />
              </Flex>
              <Flex direction={"column"} color={"black"} pb={2}>
                <Text>Last Name</Text>
                <Input
                  placeholder={userProps?.lastName}
                  value={userProps?.lastName}
                  s
                  variant="outline"
                  _placeholder={{ color: "gray.500" }}
                  backgroundColor={"#8080801a"}
                  onChange={(e) =>
                    setUserProps({
                      ...userProps,
                      lastName: e.target.value,
                    })
                  }
                />
              </Flex>
              <Flex direction={"column"} color={"black"} pb={2}>
                <Text>Email Address</Text>
                <Input
                  placeholder={userProps?.email}
                  value={userProps?.email}
                  variant="outline"
                  _placeholder={{ color: "gray.500" }}
                  backgroundColor={"#8080801a"}
                  disabled
                />
              </Flex>
              <Flex direction={"column"} color={"black"} pb={2}>
                <Text>Password</Text>
                <Input
                  placeholder={"********"}
                  variant="outline"
                  _placeholder={{ color: "gray.500" }}
                  backgroundColor={"#8080801a"}
                  disabled
                />
              </Flex>
              <Flex direction={"column"} color={"black"} pb={2}>
                <Text>Registiration Date</Text>
                <Input
                  placeholder={userProps?.created_at}
                  variant="outline"
                  _placeholder={{ color: "gray.500" }}
                  backgroundColor={"#8080801a"}
                  disabled
                />
              </Flex>
              <Flex direction={"column"} color={"black"} pb={2}>
                <Text color={"black"}>Account Status</Text>
                <Input
                  placeholder={userProps?.role}
                  value={userProps?.role}
                  _placeholder={{ color: "gray.500" }}
                  variant="outline"
                  borderColor={"black"}
                  onChange={(e) =>
                    setUserProps({
                      ...userProps,
                      role: e.target.value,
                    })
                  }
                />
              </Flex>
              <Flex direction={"column"} color={"black"} pb={2}>
                <Text>Amount Spent</Text>
                <Input
                  placeholder={userProps?.amount}
                  value={userProps?.amount}
                  variant="outline"
                  _placeholder={{ color: "gray.500" }}
                  backgroundColor={"#8080801a"}
                  disabled
                />
              </Flex>
              <Flex direction={"column"} color={"black"} pb={2}>
                <Text>Api Keys Status</Text>
                <Input
                  placeholder={"API keys"}
                  defaultValue={"Bybit API keys are connected succesfully"}
                  variant="outline"
                  borderColor={"black"}
                  disabled
                />
              </Flex>
            </Flex>
          </Flex>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Flex
            className="buttons-container"
            direction={"row"}
            justifyContent={"space-between"}
            w={"full"}
            pt={0}
          >
            <Button
              backgroundColor={"blue.400"}
              rounded={"md"}
              _hover={{ backgroundColor: "blue.500" }}
            >
              Modify
            </Button>
            <Button
              backgroundColor={"green.400"}
              rounded={"md"}
              _hover={{ backgroundColor: "green.500" }}
              onClick={updateData}
            >
              Apply
            </Button>
          </Flex>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
    // <Box position={"fixed"} top={'6%'} left={'45%'} hidden={hidden} zIndex={1000} width={'25%'}
    // >
    //     <Flex direction={'column'} backgroundColor={'white'} rounded={'lg'} p={4} alignItems={'center'} w={'full'} >
    //         <Flex direction={'column'}  pb={8} color={"black"}>
    //             <Image src={userImage?userImage:sample}  />
    //             <Text fontSize={'18px'} fontWeight={'bold'} pt={2} >User Information</Text>
    //         </Flex>
    //         <Flex direction={'column'} w={'full'} >
    //             <Flex direction={'column'} color={"black"} pb={2}>
    //                 <Text>First Name</Text>
    //                 <Input placeholder={firstName?firstName:'John'} variant='outline'  _placeholder={{ color: 'gray.500' }}  backgroundColor={"#8080801a"}/>
    //             </Flex>
    //             <Flex direction={'column'} color={"black"} pb={2}>
    //                 <Text>Last Name</Text>
    //                 <Input placeholder={lastName?lastName:'Doe'} variant='outline' _placeholder={{ color: 'gray.500' }}  backgroundColor={"#8080801a"}/>
    //             </Flex>
    //             <Flex direction={'column'} color={"black"} pb={2}>
    //                 <Text>Email Address</Text>
    //                 <Input placeholder={email?email:'johndoe@example.com'} variant='outline' _placeholder={{ color: 'gray.500' }}  backgroundColor={"#8080801a"}/>
    //             </Flex>
    //             <Flex direction={'column'} color={"black"} pb={2}>
    //                 <Text>Password</Text>
    //                 <Input placeholder={password?password:'********'} variant='outline' _placeholder={{ color: 'gray.500' }}  backgroundColor={"#8080801a"}/>
    //             </Flex>
    //             <Flex direction={'column'} color={"black"} pb={2}>
    //                 <Text>Registiration Date</Text>
    //                 <Input placeholder={regDate?.split("T")[0]}  variant='outline' _placeholder={{ color: 'gray.500' }}  backgroundColor={"#8080801a"}/>
    //             </Flex>
    //             <Flex direction={'column'} color={"black"} pb={2}>
    //                 <Text color={"black"}>Account Status</Text>
    //                 <Input placeholder={accStatus?.toString()} _placeholder={{ color: 'gray.500' }} variant='outline' borderColor={"black"} />
    //             </Flex>
    //             <Flex direction={'column'} color={"black"} pb={2}>
    //                 <Text>Amount Spent</Text>
    //                 <Input placeholder={amountSpent?amountSpent:'0'} variant='outline' _placeholder={{ color: 'gray.500' }}  backgroundColor={"#8080801a"}/>
    //             </Flex>
    //             <Flex direction={'column'} color={"black"} pb={2}>
    //                 <Text>Api Keys Status</Text>
    //                 <Input placeholder={apiKeysStatus?apiKeysStatus:''} defaultValue={'Bybit API keys are connected succesfully'} variant='outline' borderColor={"black"} />
    //             </Flex>
    //         </Flex>

    //         <Flex className="buttons-container" direction={'row'} justifyContent={"space-between"} w={"full"} pt={0}>
    //             <Button backgroundColor={'blue.400'} rounded={'md'} _hover={{backgroundColor:'blue.500'}}>Modify</Button>
    //             <Button backgroundColor={'green.400'} rounded={'md'} _hover={{backgroundColor:'green.500'}}>Apply</Button>
    //         </Flex>
    //     </Flex>
    // </Box>
  );
}
