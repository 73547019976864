/* eslint-disable */
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
// ProRIse-admin imports
import {
  Box,
  Center,
  Divider,
  Flex,
  HStack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

export function SidebarLinks(props) {
  //   Chakra color mode
  let location = useLocation();
  let activeColor = useColorModeValue("gray.700", "gary.200");
  let inactiveColor = useColorModeValue("gary.200", "gary.200");
  let activeIcon = useColorModeValue("brand.500", "white");
  let textColor = useColorModeValue("gary.200", "#A0AEC0");

  const { routes } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  const createLinks = (routes) => {
    return routes.map((route, index) => {
      if (route.category) {
        return (
          <>
            <Text
              fontSize={"md"}
              color={activeColor}
              fontWeight="bold"
              mx="auto"
              ps={{
                sm: "10px",
                xl: "16px",
              }}
              pt="18px"
              pb="12px"
              key={index}
              textTransform={"capitalize"}
            >
              {route.name}
            </Text>
            {createLinks(route.items)}
          </>
        );
      } else if (route.layout === "/admin") {
        return (
          <NavLink key={index} to={route.layout + route.path}>
            <Box>
              <HStack
                spacing={
                  activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                }
                py="15px"
                ps="10px"
              >
                <Flex w="100%" alignItems="center" justifyContent="center">
                  <Box
                    color={
                      activeRoute(route.path.toLowerCase())
                        ? activeIcon
                        : textColor
                    }
                    me="18px"
                  >
                    {route.icon}
                  </Box>
                  <Text
                    me="auto"
                    color={
                      activeRoute(route.path.toLowerCase())
                        ? activeColor
                        : textColor
                    }
                    textTransform={"uppercase"}
                    fontWeight={
                      activeRoute(route.path.toLowerCase()) ? "bold" : "normal"
                    }
                    fontSize={19}
                  >
                    {route.name}
                  </Text>
                </Flex>
              </HStack>
              <Divider
                borderColor={"white"}
                variant={"solid"}
                borderWidth={1}
              />
            </Box>
          </NavLink>
        );
      }
    });
  };
  //  BRAND
  return createLinks(routes);
}

export default SidebarLinks;
